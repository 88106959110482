import Event from "../pages/Event";
import Home from "../pages/Home";

export const site_paths = {
  home: "/",
  event: "/event",
};

export const site_routes = [
  {
    path: site_paths.home,
    component: Home,
  },
  {
    path: site_paths.event,
    component: Event,
  },
];
