"use client";
import { PiEyeLight, PiEyeSlashLight } from "react-icons/pi";
import { useEffect, useState } from "react";

/**
 * Renders an input component.
 *
 * @param label - The label for the input.
 * @param className - The class name for the input.
 * @param type - The type of the input.
 * @param error - The error message for the input.
 * @param onChange - The event handler for the input change event.
 * @param onBlur - The event handler for the input blur event.
 * @param value - The value of the input.
 * @param name - The name of the input.
 * @param defaultValue - The default value of the input.
 * @param placeholder - The placeholder text for the input.
 * @param isDisabled - Whether the input is disabled.
 */
export default function Input({
  label,
  className,
  type,
  error,
  onChange,
  onBlur,
  value,
  name,
  defaultValue,
  placeholder,
  isDisabled,
  required = false,
}: InputType) {
  const [labelActive, setLabelClass] = useState("translate(10%, 30%)");
  const [inputType, setInputType] = useState("text");
  const [errorClass, setErrorClass] = useState("");

  /**
   * Toggles the visibility of the input value.
   */
  const handleVisibility = () => {
    if (inputType === "password") {
      setInputType("text");
    } else {
      setInputType("password");
    }
  };

  useEffect(() => {
    if (error && error.length > 0) {
      setErrorClass("block");
    } else {
      setErrorClass("hidden");
    }

    // Clean up error class
    return () => {
      setErrorClass("");
    };
  }, [error]);

  useEffect(() => {
    setInputType(type || "text");
    let timeout = setTimeout(() => {
      setLabelClass("translate(8%, -140%)");
    }, 1000);
    // Clean Up Here
    return () => {
      clearTimeout(timeout);
    };
  }, []);
  // Clean Up Here
  useEffect(() => {
    return () => {
      setLabelClass("");
      setErrorClass("");
      setInputType("text");
    };
  }, []);
  return (
    <div
      className={
        "w-full bg-[#0D0D0D] outline-none sm:h-10 h-9 relative my-[15px] rounded-[10px] border-[#817c7c] border-[1px] py-[5px] "
      }
    >
      <label
        className={`absolute text-[#E6E6E6] text-sm md:text-base ${labelActive}`}
        htmlFor="email"
        style={{
          transition: "all 0.5s",
          transform: labelActive || "translate(20%, 30%)",
        }}
      >
        {label}
      </label>
      <span
        className={`absolute text-[#f20808] text-xs md:text-base right-[2%] top-[-60%] ${errorClass}`}
      >
        {error}
      </span>
      <input
        onChange={onChange}
        onBlur={onBlur}
        value={value || ""}
        type={inputType}
        name={name}
        required={required}
        disabled={isDisabled}
        defaultValue={defaultValue}
        className={`bg-transparent text-[#E6E6E6] text-sm md:text-base outline-none sm:h-10 h-8 w-full rounded-[10px] py-[10px] mt-[-5px] px-[20px]`}
        placeholder={placeholder}
      />
      <button
        onClick={handleVisibility}
        className={`absolute top-[28px] right-[30px] outline-none cursor-pointer bg-transparent border-none ${
          type === "password" ? "block" : "hidden"
        }`}
        type="button"
      >
        {inputType === "password" ? <PiEyeLight /> : <PiEyeSlashLight />}
      </button>
    </div>
  );
}
