import { FaMinus } from "react-icons/fa";
import Button from "../Elements/Button";
import AppModal from "./AppModal";
import { FaPlus } from "react-icons/fa6";
import useCounter from "../../hooks/useCounter";
import Input from "../Elements/Input";
import { useState } from "react";
import { useFormik } from "formik";
import ticketSchema from "../../schemas/ticket.schema";

export default function TicketModal({
  isOpen,
  handleClose,
}: {
  isOpen: boolean;
  handleClose: () => void;
}) {
  const { value, increment, decrement } = useCounter();
  const ticketForm = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      use_phone: false,
    },
    validateOnBlur: false,
    validateOnMount: false,
    // validateOnChange: true,
    validationSchema: ticketSchema,
    onSubmit: (values, { setSubmitting, setFieldError }) => {
      setSubmitting(true);
      console.log(values);
    },
  });
  return (
    <AppModal isOpen={isOpen} handleClose={handleClose} title="">
      <div className="flex flex-col px-8 py-4  gap-4">
        <div className="flex justify-between">
          <p>Regular</p>
          <div className="flex gap-6">
            <Button
              onClick={decrement}
              className=" px-2 text-black bg-[#E6E6E6] cursor-pointer"
            >
              <FaMinus size={10} />
            </Button>
            <p className="sm:text-xl text-base">
              <b>{value}</b>
            </p>
            <Button
              onClick={increment}
              className=" px-2 text-black bg-[#E6E6E6] cursor-pointer"
            >
              <FaPlus size={10} />
            </Button>
          </div>
        </div>
        <form onSubmit={ticketForm.handleSubmit}>
          <div className="flex flex-col gap-4 justify-center pt-10 pb-3">
            <Input
              label="First Name"
              name="first_name"
              value={ticketForm.values.first_name}
              onChange={ticketForm.handleChange}
              onBlur={ticketForm.handleBlur}
              error={ticketForm.errors.first_name}
            />
            <Input
              label="Last Name"
              name="last_name"
              value={ticketForm.values.last_name}
              onChange={ticketForm.handleChange}
              onBlur={ticketForm.handleBlur}
              error={ticketForm.errors.last_name}
            />
            <div className="py-0">
              <input
                type="checkbox"
                className="bg-[#0D0D0D] cursor-pointer rounded-sm py-1 px-2"
                name="use_phone"
                onChange={ticketForm.handleChange}
                checked={ticketForm.values.use_phone}
                id="use_phone"
              />{" "}
              <label htmlFor="use_phone" className="text-sm cursor-pointer">
                {" "}
                Receive ticket via phone ?
              </label>
            </div>
            {ticketForm.values.use_phone ? (
              <Input
                label="Phone"
                name="phone"
                value={ticketForm.values.phone}
                onChange={ticketForm.handleChange}
                onBlur={ticketForm.handleBlur}
                error={ticketForm.errors.phone}
                required={true}
              />
            ) : (
              <Input
                label="Email"
                name="email"
                value={ticketForm.values.email}
                type="email"
                onChange={ticketForm.handleChange}
                onBlur={ticketForm.handleBlur}
                error={ticketForm.errors.email}
              />
            )}

            <div className="flex justify-between px-6">
              <p>Total</p>
              <p className="text-3xl">
                <b>20000</b>
              </p>
            </div>
            <div className="flex justify-center">
              <Button className="bg-[#1BB150] text-white px-14 py-3 text-sm rounded-lg">
                Buy Tickets
              </Button>
            </div>
          </div>
        </form>
        <div className="flex flex-col items-center ">
          <p>Powered by</p>
          <img src="/logo.png" alt="log" className="w-10" />
        </div>
      </div>
    </AppModal>
  );
}
