"use client";
import { PulseLoader } from "react-spinners";
import { motion } from "framer-motion";

/**
 * A custom button component.
 * @param loading - Whether the button is in a loading state.
 * @param children - The content of the button.
 * @param onClick - The function to be called when the button is clicked.
 * @param className - Additional CSS class names for the button.
 * @param href - The URL the button should navigate to when clicked.
 * @param id - The unique identifier for the button.
 * @param type - The type of the button.
 * @param isDisabled - Whether the button is disabled.
 * @param title - The title attribute for the button.
 */
export default function Button({
  loading,
  children,
  onClick,
  className,
  id,
  type,
  isDisabled,
  title,
}: ButtonType) {
  // Render the button component
  return (
    <motion.button
      whileHover={{ scale: 1.02 }}
      onHoverStart={(e) => {}}
      onHoverEnd={(e) => {}}
      onClick={onClick}
      whileTap={{ scale: 1.03 }}
      className={`${className}`}
      disabled={loading || isDisabled}
      id={id}
      type={type}
      title={title}
    >
      {loading ? <PulseLoader loading={true} color="#fde82a" /> : children}
    </motion.button>
  );
}
