import { Link, useNavigate } from "react-router-dom";
import MotionBox from "./MotionBox";

export default function UpcomingEventCard() {
  const navigate = useNavigate();
  return (
    <MotionBox
      onClick={() => navigate("/event")}
      animationType="fadeLeft"
      className="text-[#E6E6E6] w-full cursor-pointer"
    >
      <div>
        <img src="/images/event.png" alt="Card" className="w-full h-64" />
      </div>
      <h2 className="sm:text-lg text-base font-bold font-Boris py-2">
        My City My Paint
      </h2>
      <p className="text-[#808080] sm:text-base text-sm leading-snug py-4">
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Obcaecati
        aperiam quam laudantium ducimus veritatis fugit fuga temporibus sapiente
        aliquid. Ipsa eveniet repudiandae velit officiis officia aperiam vero
        modi vitae ab.
      </p>
      <Link
        to="/events"
        className="text-[#E6E6E6] bg-[#1BB150] w-full text-center sm:text-base text-sm rounded-sm py-2 px-1 block"
      >
        Get ticket
      </Link>
    </MotionBox>
  );
}
