import { useEffect, useState } from "react";
import HomeLoading from "../components/Modules/HomeLoading";
import Navbar from "../components/Modules/Navbar";
import Footer from "../components/Modules/Footer";
import MotionBox from "../components/Modules/MotionBox";
import {
  LocationIcon,
  TicketIcon,
  TimeIcon,
} from "../components/Elements/AppIcons";
import TicketModal from "../components/Modules/TicketModal";
import Button from "../components/Elements/Button";

export default function Event() {
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  return (
    <div className="text-3xl h-screen bg-black m-o font-Boris">
      {loading ? (
        <HomeLoading />
      ) : (
        <>
          <Navbar />
          <main>
            {/* Heror section */}
            <section>
              <div className="flex flex-col-reverse md:flex-row justify-around items-center w-full gap-8 md:px-28 px-0 py-0 h-auto ">
                <MotionBox
                  animationType="fadeIn"
                  className=" w-full md:px-8 px-0 flex  items-center  text-center text-white bg-cover md:h-[60vh] h-[50vh] bg-center"
                >
                  <img
                    src="/images/event-banner.png"
                    className="md:w-[30rem] w-full object-cover"
                    alt="hero"
                  />
                </MotionBox>
                <MotionBox
                  className={
                    "text-white w-full flex flex-col gap-5 py-6 md:py-0 justify-start text-center h-[100%]"
                  }
                  animationType="fadeLeft"
                >
                  <h1 className="sm:text-3xl text-xl px-10 sm:px-0 font-Boris">
                    SIP AND PAINT ABUJA
                  </h1>
                  <p className="text-sm font-james tracking-widest ">
                    <span>26 oct 2024</span> <br />
                    <span>Starts at 8:00am</span>
                  </p>
                  <a href="#ticket">
                    <button className="bg-[#E6E6E6] text-black px-8 py-2 text-xs md:text-sm rounded-sm">
                      Buy Ticket
                    </button>
                  </a>
                </MotionBox>
              </div>
            </section>
            {/* Event section */}
            <section>
              <div
                id="ticket"
                className="bg-[#1A1A1A] flex flex-col items-center py-10"
              >
                <MotionBox
                  animationType="fadeIn"
                  className="md:w-[50%] w-full px-6"
                >
                  <h2 className="text-[#fff] text-start text-base font-Boris">
                    About this event :
                  </h2>
                  <p className="py-2 md:text-start text-center mt-4">
                    <span className="bg-[#808080] text-[#fff] text-sm rounded-full px-10 py-2 mt-4">
                      Organised by My City My Paint
                    </span>
                  </p>
                  <p className="text-[#808080] sm:text-base text-sm leading-snug py-4">
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    Obcaecati aperiam quam laudantium ducimus veritatis fugit
                    fuga temporibus sapiente aliquid. Ipsa eveniet repudiandae
                    velit officiis officia aperiam vero modi vitae ab. Lorem
                    ipsum, dolor sit amet consectetur adipisicing elit. Illo
                    animi adipisci earum deserunt debitis quisquam, veritatis
                    perspiciatis nobis voluptatum quas officiis sapiente facilis
                    cupiditate amet dolores iusto. Eaque amet rerum officiis
                    repellat beatae nulla odio numquam incidunt praesentium
                    cumque quas, autem accusamus culpa excepturi corporis fuga
                    neque. Nam, eos. Dicta velit quo nihil aliquam cupiditate
                    similique beatae maxime voluptates sequi! Quidem dignissimos
                  </p>
                </MotionBox>

                <div className="flex md:w-[47%] w-[90%] xl:justify-start justify-between gap-10 py-5 border-b-[#4D4D4D] border-b-[0.5px] text-[#E6E6E6] text-base font-mono">
                  <div className="flex gap-2">
                    <span className="pt-1">
                      <TimeIcon />
                    </span>
                    <span>Starts at 8:00am</span>
                  </div>
                  <div className="flex gap-2">
                    <LocationIcon /> <span>Shoprite Lugbe Abuja</span>
                  </div>
                </div>
                <div className="flex md:w-[47%] w-[90%] xl:justify-start justify-between gap-10 py-5 text-[#E6E6E6] text-base font-mono">
                  <div className="flex gap-2">
                    <span className="pt-2">
                      <TicketIcon />
                    </span>
                    <span>
                      <b>Regular</b> - 25,000
                    </span>
                  </div>
                  <div className="flex gap-2">
                    <span className="pt-2">
                      <TicketIcon />
                    </span>
                    <span>
                      <b>VIP</b> - 35,000
                    </span>
                  </div>
                </div>
                <div className="flex justify-center md:w-[47%] w-full py-4 md:py-8 text-[#E6E6E6] text-base font-mono">
                  <Button
                    onClick={() => setIsOpen(true)}
                    className="bg-[#1BB150] text-white text-xs md:text-sm px-14 py-3 rounded-lg"
                  >
                    Buy Tickets
                  </Button>
                  <TicketModal
                    isOpen={isOpen}
                    handleClose={() => setIsOpen(false)}
                  />
                </div>
              </div>
            </section>
          </main>
          <Footer />
        </>
      )}
    </div>
  );
}
