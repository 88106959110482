import { FaFacebookSquare, FaInstagramSquare } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer className="w-full flex flex-col items-center bg-black">
      <div className="w-[70%] justify-between flex flex-col-reverse items-center sm:items-start gap-4 sm:flex-row py-10">
        <div className="flex gap-4 ">
          <a
            href="https://Instagram.com/skiteboxapp"
            className=" h-8 w-8 rounded-sm flex justify-center items-center"
          >
            {" "}
            <FaInstagramSquare size={25} className="bg-gray-500" />
          </a>
          <a
            href="https://Instagram.com/skiteboxapp"
            className=" h-8 w-8 rounded-sm flex justify-center items-center"
          >
            {" "}
            <FaFacebookSquare size={25} className="bg-gray-500" />
          </a>
          <a
            href="https://Instagram.com/skiteboxapp"
            className="h-8 w-8 rounded-sm flex justify-center items-center"
          >
            {" "}
            <FaSquareXTwitter size={25} className="bg-gray-500" />
          </a>
        </div>
        <div className="flex text-sm text-[#808080] text-center flex-col gap-2">
          <span className="text-[#E6E6E6]">Links</span>
          <Link to="/">Home</Link>
          <a href="/terms.pdf" target="_blank">
            Terms of Service
          </a>
          <a href="/privacy.pdf" target="_blank">
            Privacy Policy
          </a>
        </div>
      </div>
      <div className="sm:w-[70%] w-[96%] px-6 border-y-[0.5px]  border-y-[#4D4D4D] flex justify-center gap-0 sm:text-lg text-[10px] text-[#E6E6E6]">
        <div className="py-3 text-end px-4 font-james border-r-[0.5px] border-r-[#4D4D4D]">
          Cliquency
        </div>
        <div className="text-start py-3 px-4 font-james">Loooty</div>
      </div>
      <div className="w-[70%] border-y-[0.5px]  pt-4 border-y-[#4D4D4D] flex flex-col gap-1 items-center justify-center sm:text-lg text-[10px] text-[#E6E6E6]">
        <div className="py-3 text-end px-4 ">
          <img src="/logo.png" alt="logo" className="sm:w-20 w-16" />
        </div>
        <div className="text-start pb-3 px-4 text-xs">
          © 2024 Skitebox.com | All Rights Reserved
        </div>
      </div>
    </footer>
  );
}
