import { EventIcon } from "../components/Elements/AppIcons";
import UpcomingEventCard from "../components/Modules/UpcomingEventCard";

import { useEffect, useState } from "react";
import HomeLoading from "../components/Modules/HomeLoading";
import MotionBox from "../components/Modules/MotionBox";
import Navbar from "../components/Modules/Navbar";
import Footer from "../components/Modules/Footer";
export default function Home() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  return (
    <div className="text-3xl h-screen bg-black m-o font-Boris">
      {loading ? (
        <HomeLoading />
      ) : (
        <>
          <Navbar />
          <main className="w-full">
            {/* Heror section */}
            <section>
              <div className="flex justify-center w-full h-auto">
                <MotionBox
                  className="md:w-[70%] w-full px-8 flex flex-col items-center justify-center text-center text-white bg-cover md:h-[85vh] h-[50vh] bg-center"
                  style={{ backgroundImage: "url(/images/hero.png)" }}
                >
                  <h1 className="sm:text-3xl text-xl px-10 sm:px-0 font-Boris">
                    Buy and Sell Event Tickets on Skitebox
                  </h1>
                  <p className="text-sm font-james tracking-widest">
                    We meet you at the event
                  </p>
                </MotionBox>
              </div>
            </section>
            {/* Event section */}
            <section>
              <div className="bg-[#1A1A1A] flex flex-col items-center py-10">
                <div className="md:w-[70%] w-full px-6">
                  <h2 className="text-[#E6E6E6] text-start text-base font-Boris">
                    <span className="flex gap-1">
                      {" "}
                      <EventIcon />
                      Upcoming Events
                    </span>
                  </h2>
                </div>
                <div className="grid sm:grid-cols-2 grid-cols-1 md:gap-20 gap-10 md:w-[70%] w-full mt-5 px-6">
                  <UpcomingEventCard />
                  <UpcomingEventCard />
                </div>
              </div>
            </section>
          </main>
          <Footer />
        </>
      )}
    </div>
  );
}
