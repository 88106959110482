import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { site_routes } from "./constants/routes";

function App() {
  return (
    <Router>
      <Routes>
        <Route>
          {site_routes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={<route.component />}
            ></Route>
          ))}
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
