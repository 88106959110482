"use client";
import { ReactNode } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Modal from "react-modal";
import { MdClose } from "react-icons/md";
// import CloseIcon from "../icons/CloseIcon";

export default function AppModal({
  handleClose,
  children,
  isOpen,
  title,
  preventClose,
}: {
  handleClose: () => void;
  children?: ReactNode;
  isOpen: boolean;
  title?: string;
  preventClose?: boolean;
}) {
  return (
    <Modal
      isOpen={isOpen}
      preventScroll={false}
      onRequestClose={() => !preventClose && handleClose()}
      style={{
        content: {
          top: "45%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          padding: "0",
          background: "transparent",
          border: "none",
        },
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
      }}
    >
      {/*  @ts-ignore */}

      <div
        className={
          "2xl:w-[600px] md:w-[400px] w-[350px] text-[#E6E6E6] bg-[#1a1a1a] min-h-[91px] h-auto"
        }
      >
        <div
          // style={{ backgroundImage: "url(/images/modal-banner.png)" }}
          className={
            "flex justify-between items-start px-2 py-2 bg-cover bg-center"
          }
        >
          <h2>{title}</h2>
          <button
            style={{ background: "none", border: "none", cursor: "pointer" }}
            onClick={() => !preventClose && handleClose()}
          >
            <MdClose size={20} />
          </button>
        </div>
        <div className={""}>{children}</div>
      </div>
    </Modal>
  );
}
