import * as Yup from "yup";
// Define the validation schema using Yup
export default Yup.object({
  // Validate the first name field
  first_name: Yup.string().required("First name is required"),

  // Validate the last name field
  last_name: Yup.string().required("Last name is required"),

  // Validate the email field
  email: Yup.string()
    .email()
    .when("use_phone", {
      is: false, // When use_phone is false, email is required
      then: (schema) => schema.required("Email is required"),
    }),
  // Validate the phone field
  phone: Yup.string()

    .matches(
      /^\+?[0-9]{1,3}?[-.\s]?(\([0-9]{1,}\)|[0-9]{1,})[-.\s]?[0-9]{1,}[-.\s]?[0-9]{1,}[-.\s]?[0-9]{1,}$/,
      "Invalid phone number"
    )
    .length(11, "Invalid phone number")
    .when("use_phone", {
      is: true, // When use_phone is false, phone is required
      then: (schema) => schema.required("Phone is required"),
    }),
  use_phone: Yup.boolean(),
});
