import { Link } from "react-router-dom";
import { site_paths } from "../../constants/routes";

export default function Navbar() {
  return (
    <header>
      <nav className="flex bg-[rgb(26,26,26)] justify-between sm:py-6 lg:px-32 md:px-20 sm:px-14 px-6 py-5 text-gray-50">
        <div className=" pt-2">
          <Link to={site_paths.home}>
            {" "}
            <img
              src="/logo.png"
              className="lg:w-[5rem] m:w-18 sm:w-14 w-12"
              alt="site logo"
            />
          </Link>
        </div>
        <div className="flex gap-3">
          <div>
            {" "}
            <p className=" sm:text-sm text-[#E6E6E6] text-xs sm:px-7 sm:py-2 px-2 py-1 ">
              {" "}
              <span>Sign in </span>
            </p>
          </div>
          <div>
            <p className="sm:text-sm text-[#E6E6E6] text-xs sm:px-7 sm:py-2 px-2 py-1 border-x-[#E6E6E6] border-[0.5px]">
              Sign up
            </p>
          </div>
        </div>
      </nav>
    </header>
  );
}
