import { BarLoader } from "react-spinners";
import { LogoIcon } from "../Elements/AppIcons";

export default function HomeLoading() {
  return (
    <div className="relative w-full h-screen">
      <div className="text-3xl absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 text-gray-50  bg-black m-o font-Boris">
        <div className="flex items-center justify-center flex-col gap-4">
          {/* <img src="/logo.png" className="w-16 sm:w-32 " alt="logo" /> */}
          <LogoIcon />
          <BarLoader color="#ffffff" width={"150px"} />
        </div>
      </div>
    </div>
  );
}
